body {
  /* because of the Back button at the bottom of the screen */
  margin-bottom: 60px;

  /* change theme transition */
  transition: 0.15s;
}

/* shadow */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dark .card,
.dark .jumbotron {
  box-shadow: 0 4px 8px 0 rgba(149, 183, 183), 0 3px 10px 0 rgba(149, 183, 183);
}



/* NAVBAR */
nav.navbar .navbar-brand {
  background-color: white;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

nav.navbar .navbar-brand:hover {
  background-color: #343d83;
  transition: 0.5s;
}

nav.navbar button.navbar-toggler {
  border: 2px solid black;
  border-radius: 5px;
  background-color: #ccccff;
}

ul.navbar-nav li.nav-item {
  background-color: white;
  border-radius: 5px;
}

ul.navbar-nav li.nav-item .nav-link {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

ul.navbar-nav li.nav-item:hover,
ul.navbar-nav button.nav-item:hover {
  background-color: #343d83;
  transition: 0.5s;
}

ul.navbar-nav li.nav-item:hover .nav-link,
ul.navbar-nav li.nav-item:hover .logout button {
  color: white;
}

ul.navbar-nav li.nav-item .logout button {
  background: none;
  font-size: 20px;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.dropdown button {
  background-color: white;
  font-size: 20px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  padding: 9px;
}

.dropdown button:hover {
  background-color: #343d83;
  transition: 0.5s;
  color: white;
}

.dropdown .dropdown-menu .nav-item:hover {
  background-color: #343d83;
  transition: 0.5s;
  color: white;
}

@media (min-width: 992px) {
  .navbar .dropdown {
    display: inline-block !important;
  }
}

@media (max-width: 991px) {
  ul.navbar-nav li.nav-item {
    background: none;
    margin-right: 0;
    text-align: center;
    display: block !important;
  }

  .dropdown button {
    width: 100%;
    background: none;
  }
}

/* END OF NAVBAR */


ul.decor-none {
  list-style-type: none;
}

.font-bold {
  font-weight: bold;
}

/* FORM */
/* .select-corporate-client {
  color: black;
} */

/* ORDER */
.order {
  background-color: #d4e4f7;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid black;
}

.order-bg-red {
  background-color: red !important;
  color: white !important;
}

.order-bg-green {
  background-color: green !important;
  color: white !important;
}

.order-bg-blue {
  background-color: blue !important;
  color: white !important;
}

.order-bg-orange {
  background-color: orange !important;
  color: black !important;
}

.order-bg-yellow {
  background-color: yellow !important;
  color: black !important;
}

.order-bg-violet {
  background-color: violet !important;
  color: black !important;
}

.order-bg-brown {
  background-color: brown !important;
  color: white !important;
}

.order-bg-gold {
  background-color: gold !important;
  color: black !important;
}

.order-bg-greenyellow {
  background-color: greenyellow !important;
  color: black !important;
}

.order-bg-indigo {
  background-color: indigo !important;
  color: white !important;
}

.order-bg-lightsteelblue {
  background-color: lightsteelblue !important;
  color: black !important;
}

.order-bg-orchid {
  background-color: orchid !important;
  color: black !important;
}

.order-bg-seagreen {
  background-color: seagreen !important;
  color: white !important;
}

.order-bg-turquoise {
  background-color: turquoise !important;
  color: black !important;
}

.order-bg-bisque {
  background-color: bisque !important;
  color: black !important;
}

.order-bg-cyan {
  background-color: cyan !important;
  color: black !important;
}

.order-bg-darkgoldenrod {
  background-color: darkgoldenrod !important;
  /* color: #ecf9f1 !important; */
  color: white !important;
  /* color: black !important; */
}

/* END OF ORDER */


/* MESSAGES */
#container-messages {
  min-height: 100%;
  overflow: auto;
  margin-top: 62px;
  margin-bottom: 70px;
}

.message-form {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f1f1f1;
  z-index: 1;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.his-message {
  background-color: #66ccff;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  outline: 0px solid red;
}

.my-message {
  background-color: #437c4c;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.my-message .message-content p {
  color: white;
}

.message-author {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #d4e4f7;
}

.message-author p {
  font-weight: bold;
  color: green;
  display: inline;
}

.message-content {
  padding: 2px 10px;
  border-bottom: 1px solid #9e9e9e;
  border-top: 1px solid #9e9e9e;
}

.message-content p {
  font-size: 19px;
  font-weight: bold;
}

.message-date {
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #d4e4f7;
}

.message-date p {
  color: grey;
}

button.edit-message,
button.delete-message {
  float: right;
}

/* END OF MESSAGES */


/* ANNOUNCEMENTS */
.anons {
  border: 2px solid black;
  border-radius: 5px;
}

.anons-author {
  padding: 5px 10px;
  background-color: #5F5ABF;
  border-bottom: 2px solid #320C40;
}

.anons-author p {
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.anons-content {
  padding: 5px 10px;
  background-color: #89A7CE;
  border-bottom: 2px solid #320C40;
}

.anons-content p {
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.anons-date {
  padding: 5px 10px;
  background-color: #493B9A;
}

.anons-date p {
  color: white;
  font-weight: bold;
  font-size: 18px;
}

/* END OF ANNOUNCEMENTS */

.border-between-disinfectors {
  border-top: 3px solid red;
}



/* CALENDAR SELECT WEEKS */
.SelectedWeekExample .DayPicker-Month {
  border-collapse: separate;
}

.SelectedWeekExample .DayPicker-WeekNumber {
  outline: none;
}

.SelectedWeekExample .DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
}

.SelectedWeekExample .DayPicker-Day--selectedRange {
  background-color: #fff7ba !important;
  border-top-color: #FFEB3B;
  border-bottom-color: #FFEB3B;
  border-left-color: #fff7ba;
  border-right-color: #fff7ba;
}

.SelectedWeekExample .DayPicker-Day--selectedRangeStart {
  background-color: #FFEB3B !important;
  border-left: 1px solid #FFEB3B;
}

.SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
  background-color: #FFEB3B !important;
  border-right: 1px solid #FFEB3B;
}

.SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
  border-radius: 0 !important;
  color: black !important;
}

.SelectedWeekExample .DayPicker-Day--hoverRange:hover {
  border-radius: 0 !important;
}

/* END OF CALENDAR SELECT WEEKS */


.border-bottom {
  border-bottom: 4px solid black;
  margin-top: 8px;
  margin-bottom: 8px;
}

.border-bottom-red {
  border-bottom: 2px solid red;
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-bg {
  background-color: #d4e4f7;
  border-radius: 15px;
}

.client-stats-order {
  border-bottom: 4px solid red;
}



/* CLIENTS TABLE */
.table {
  text-align: center;
  vertical-align: center;
  font-weight: bold;
}

.table tbody th,
.table tbody td {
  border: 2px solid black;
}

.table thead th {
  border: 2px solid black;
}

.table td,
.table th {
  padding: 8px 4px;
}

/* END OF CLIENTS TABLE */



/* TABS IN USERS PAGE */
ul.nav-tabs {
  border: 3px solid #e9e9e9;
  border-radius: 10px;
}

ul.nav-tabs li:hover {
  outline: 1px solid #9f9d9d;
}

ul.nav-tabs li a {
  color: black;
  font-weight: bold;
}

ul.nav-tabs li a.active {
  background-color: #007bff !important;
  color: white !important;
  transition: 0.5s;
}

/* END OF TABS IN USERS PAGE */



/* FOOTER */
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer button {
  margin: 2px 0;
  font-size: 18px;
}

/* END OF FOOTER */